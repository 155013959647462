<template>
    <div class="MobBasics">
        手机版暂为开放
    </div>
</template>

<script>
export default {
    name:"MobBusinessmanagement"
}
</script>

<style lang="less">
// .MobBusinessmanagement{}
</style>